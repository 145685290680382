// Sale model line
import mainStore from "store/mainStore";

import ProductCombine from "./ProductCombine";
import ProductCombo from "./ProductCombo";
import ProductDiscount from "./ProductDiscount";
// import proxy from "api/proxy";

const getProduct = (value) => {
  const { record } = mainStore.getState();
  if (!value || value === "") return;
  let category_ids = [];
  if (record?.shop?.product_categories.length) {
    category_ids = record.shop.product_categories;
  }
  if (record?.shop && record?.shop["product_categories."]) {
    for (const item of record.shop["product_categories."]) {
      category_ids = [...category_ids, ...item.childs];
    }
  }
  let domain = [
    ["template.active", "=", true],
    ["active", "=", true],
    ["template.salable", "=", true],
  ];
  if (category_ids) {
    domain.push(["template.categories", "in", category_ids]);
  }

  const target_words = value.split(" ");
  target_words.forEach((tw) => {
    if (tw.length <= 1) {
      return;
    }
    const clause = [
      "OR",
      ["template.name", "ilike", `%${tw}%`],
      ["description", "ilike", `%${tw}%`],
      ["code", "ilike", `%${tw}%`],
      ["barcode", "=", `%${tw}%`],
    ];
    domain.push(clause);
  });
  return domain;
};

// const getSalePrices = async (activeRecord, parentRecord) => {
//   const { data: unit_price } = await proxy.methodInstance({
//     model: "product.product",
//     method: "_get_sale_unit_price",
//     instance: activeRecord?.product?.id ?? activeRecord.product,
//     args: [activeRecord.quantity],
//     ctx: {
//       price_list: parentRecord?.price_list?.id,
//       uom: activeRecord.unit ?? null,
//       customer: parentRecord?.party?.id,
//     },
//   });
//   if (!activeRecord.product) {
//     return {};
//   }

//   const { data: price_w_tax } = await proxy.methodCall({
//     model: "sale.sale",
//     method: "dash_get_amount_w_tax",
//     args: [
//       {
//         product: activeRecord.product["id"],
//         list_price: unit_price,
//       },
//     ],
//   });

//   // const factor = activeRecord.unit.factor; // ???? bug
//   return {
//     unit_price: unit_price,
//     price_w_tax: price_w_tax,
//   };
// };

const onChangeProduct = async (activeChild, storeChild) => {
  // const activeRecord = parentRec.record;
  const product = activeChild?.product;
  if (!product) return;
  let unit = product["sale_uom."];
  const { sale_price_taxed, list_price } = product;

  // let { unit_price, price_w_tax } = await getSalePrices(
  //   activeChild,
  //   activeRecord,
  // );

  storeChild.unit_price = list_price;
  storeChild.base_price = list_price;
  storeChild.unit = unit.id;
  storeChild.description = product.name;

  activeChild.unit_price = list_price;
  activeChild.base_price = list_price;
  activeChild.unit = unit;
  activeChild.amount = sale_price_taxed;
  activeChild.total_amount = sale_price_taxed;
};

// const onChangeUnit = async (storeRec, activeRecord, parentRecord) => {
//   let { unit_price, price_w_tax } = await getSalePrices(
//     storeRec,
//     activeRecord,
//     parentRecord,
//   );
//   storeRec.unit_price = unit_price;
//   activeRecord.unit_price = unit_price;
//   storeRec.amount = price_w_tax;
//   activeRecord.amount = price_w_tax;
//   return storeRec, activeRecord;
// };

const onChangeQty = (record) => {
  if (record.quantity && record.amount) {
    const qty = record.quantity;
    if (record.discount) {
      const { amount, discount } = record;
      const amount_ = amount * qty - (amount * qty * discount) / 100;
      record.total_amount = amount_;
    } else {
      record.total_amount = record.amount * qty;
    }
  }
};

const searchUnit = (value, record) => {
  let dom = [["category", "=", record?.unit?.category]];
  if (value) {
    dom.push(["name", "ilike", `%${value}%`]);
  }
  return dom;
};

const getTotalAmount = (activeRecord) => {
  let total_amount = 0;
  if (activeRecord.amount && activeRecord.quantity) {
    total_amount = activeRecord.amount * activeRecord.quantity;
  }
  return total_amount;
};

const onChangeDeliveryFee = async (record, store) => {
  const { delivery_fee, quantity } = record;
  if (delivery_fee && quantity) {
    record.total_amount = delivery_fee * quantity;
    record.amount = delivery_fee;
    store.unit_price = delivery_fee;
    store.base_price = delivery_fee;
  }
};

const getView = () => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    selectable: "one",
    model: "sale.line",
    row_selectable: false,
    table_action: ["delete", "add", "edit"],
    form_action: ["add", "edit"],
    target: "sale",
    webfields: {
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: getProduct,
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
          "categories",
          "products_mix",
        ],
        required: true,
      },
      unit: {
        type: "many2one",
        model: "product.uom",
        recSearch: searchUnit,
        // withChange: onChangeUnit,
        readOnly: true,
        attrs: ["id", "name", "category", "factor"],
      },
      quantity: {
        type: "float",
        withChange: onChangeQty,
        required: true,
        default: 1,
      },
      note: { type: "text" },
      unit_price: { type: "numeric", readOnly: true, decimalPlaces: 2 },
      delivery_fee: {
        type: "numeric",
        visible: (field, rec) => {
          return rec.set_delivery_fee;
        },
        dependents: ["set_delivery_fee"],
        withChange: onChangeDeliveryFee,
      },
      set_delivery_fee: { type: "boolean" },
      amount: { type: "numeric", readOnly: true, decimalPlaces: 2 },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
      },
    },
    webtree: [
      { name: "product", width: "40%" },
      { name: "quantity", width: "10%" },
      { name: "amount", width: "23%" },
      { name: "total_amount", width: "23%" },
    ],
    webform: [
      { name: "product" },
      { name: "quantity" },
      { name: "amount" },
      { name: "total_amount" },
      { name: "note" },
      {
        id: "fee",
        grid: [
          { name: "set_delivery_fee", dependents: ["delivery_fee"] },
          { name: "delivery_fee" },
        ],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "buttons",
        grid: [
          {
            type: "button_custom_modal",
            Component: ProductCombo,
            color: "lime",
            icon: "fi fi-bs-hamburger-soda",
            name: "button_combo",
            button_ok: false,
          },
          {
            type: "button_custom_modal",
            Component: ProductCombine,
            color: "indigo",
            icon: "fi fi-rr-pizza-slice",
            name: "button_combine",
            button_ok: false,
          },
          {
            type: "button_custom_modal",
            Component: ProductDiscount,
            color: "rose",
            icon: "fi fi-rr-badge-percent",
            name: "button_discount",
            button_ok: false,
          },
        ],
        size: [1, 3],
        span: "col-span-2",
      },
    ],
  };
  return DictCtxView;
};

export default { ctxView: getView };
